<template>
    <div id="bee-plugin-container"></div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode'

export default {
    components: {
        VueJwtDecode
    },
    data() {
        return{

        }
    },
    created() {
        this.authorizeBeeFreePlugin();
    },
    methods: {
        authorizeBeeFreePlugin(){
            this.$vs.loading();
            this.axios.get("/ws/EmailMarketingCampaign/AuthorizeBeeFreePlugin").then((response) => {
                let token = response.data;
                let uid = this.$route.params.id
                let jwtDecode = VueJwtDecode.decode(this.$store.state.authTokenVV[uid].token)
                this.jwtCustomerID = jwtDecode.CustomerId
                var bee;
                var config = {
                    uid: this.jwtCustomerID,
                    container: 'bee-plugin-container'
                }
                window.BeePlugin.create(token, config, function(instance) {
                    bee = instance;
                    bee.start('https://github.com/BEE-Plugin/BEE-FREE-templates/blob/master/v.3/one-column.jpg');
                });
                this.$vs.loading.close();
            }).catch((e) => {
                this.$vs.loading.close();
                this.showError(e);
            });
        }
    }
}
</script>

<style>
    
</style>
